import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import { paginateArray, sortCompare } from "@/@fake-db/utils";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BJumbotron } from "bootstrap-vue";
// import { is } from "core-js/core/object";

const getFirebaseData = async () => {
  let studentsList = [];
  // console.log(store.state);
  var studentCode = store.state.mainUserCodes.StudentCode;
  const uid = firebase.auth().currentUser.uid;

  let result = await new Promise((resolve, reject) => {
    const jobRef = firebase.firestore().collection("RecruiterJobs")
    const recRef = firebase.firestore().collection("RecruiterId").doc(uid)
    const studentRef = firebase.firestore().collection("StudentID")
    let activeJobsArray = []
    let jobsOptions = []
    let totalAppliedStudents = 0
    let parsedStudents = 0
    let recruiterCode = null;
    recRef.get().then((recruiter) => {
      activeJobsArray = recruiter.data().activeJobs ?? []
      recruiterCode = recruiter.data().RecruiterCode
    }).then(() => {
      if (activeJobsArray.length > 0) {
        activeJobsArray.forEach((jobId) => {
          jobRef.doc(jobId).get().then((jobDoc) => {
            var isCollegeJob = (jobDoc.data().Colleges == studentCode)
            if (isCollegeJob) {
              var jobObj = {
                label:jobDoc.data().JobTitle,
                value: jobDoc.id
              }
              jobsOptions.push(jobObj)
              jobRef.doc(jobId).collection("AppliedCandidates").get().then((snapshot) => {
                if(snapshot.size > 0){
                totalAppliedStudents = snapshot.size
                snapshot.forEach((document) => {
                  // console.log(document.data().uid)
                  var idy = document.data().uid
                  studentRef.doc(idy).get().then((student) => {
                    if (student.exists) {
                      var obj = {}
                      obj.jobId = jobId
                      obj.RecruiterData = {
                        RecruiterId: uid,
                        RecruiterCode: jobDoc.data().RecruiterCode,
                        CompanyName: jobDoc.data().CompanyName,
                        JobTitle: jobDoc.data().JobTitle,
                        logo: jobDoc.data().logo
                      }
                      obj.id = student.id;
                      obj.name = student.data().FullName ?? '';
                      obj.profile = student.data().profilephoto;
                      obj.courseName = student.data().courseName;
                      obj.email = student.data().email;
                      obj.currentRound = document.data().currentRound ?? 0;
                      obj.schedulebutton = document.data().isInterviewDone ?? true;
                      obj.isHired = document.data().isHired ?? false;
                      obj.status = document.data()[
                        `Round${obj.currentRound}_status`
                      ] ?? '--';
                      if (obj.currentRound > 0) {
                        obj.remark = document.data()[`Round${obj.currentRound}_remarks`];
                        // obj.remark = document.data();

                        obj.interviewID = document.data()[
                          `Round${obj.currentRound}_interviewID`
                        ];
                        obj.interview_Timestamp = document.data()[
                          `Round${obj.currentRound}_date`
                        ];

                        if (obj.status == "Hired") {
                          if (
                            typeof document.data().joiningStatus != "undefined"
                          ) {
                            obj.status = document.data().joiningStatus;
                          }
                        }
                      }
                      // console.log(obj)
                      studentsList.push(obj)
                    } else {
                      console.log("student doesn't exist")
                    }
                    parsedStudents = parsedStudents + 1
                  }).then(() => {
                    if (totalAppliedStudents == parsedStudents) {
                      var finalObj = {
                        recruiterCode : recruiterCode,
                        jobsArray : jobsOptions,
                        users: studentsList
                      }
                      resolve(finalObj)
                    }
                  })
                })
              }
              })

            }
          })
        })
      }
    })


  });

  return result;
};

let users = [];
let filteredData = [];
let jobNameList = [];
let recCode = null;
const getDataandSort = async (config) => {
  if (users.length < 1 || config.isValueUpdated == true) {
  // console.log("this is users length");
  // console.log(users.length);
  let receivedObj = await getFirebaseData();
    users = receivedObj.users
    jobNameList = receivedObj.jobsArray
    recCode = receivedObj.recruiterCode
  }
  // console.log('hi this users');
  // console.log(users.length);
  const {
    q = "",
    perPage = 10,
    page = 1,
    sortBy = "id",
    sortDesc = false,
    jobId,
    status,
    round
    
  } = config;
  
  let queryUsers = users

  const queryLowered = q.toLowerCase();
  // const filteredData = users;
  // console.log(filteredData.length);

  let user = users.filter(
    (user) =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      
      user.name.toLowerCase().includes(queryLowered) && 
      (status.length > 0 ? status.includes(user.status) : true) &&
      (round.length > 0 ? round.includes(user.currentRound) :true) &&
      (jobId.length > 0 ? (jobId.includes('all') ? true : jobId.includes(user.jobId)) : true)
    
  );

  // console.log(user.length)
  const sortedData = user.sort(sortCompare(sortBy));
  if (sortDesc) sortedData.reverse();
  filteredData = sortedData;
  return [
    200,
    {
      users: paginateArray(sortedData, perPage, page),
      total: sortedData.length,
      rawUsers: users,
      jobOptions: jobNameList,
      recruiterCode:recCode
    },
  ];
};

export function rawUserList() {
  const rawList = ref([]);

  rawList.value = users;

  return { rawList };
}

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "name",
      label: "Student Name",
      formatter: title,
      sortable: true,
    },
    {
      key: "courseName",
      label: "Course",
      formatter: title,
      sortable: true,
    },
    {
      key: "currentRound",
      label: "Current Round",
      formatter: title,
      sortable: true,
    },
    {
      key: "status",
      label: "Status",
      formatter: title,
      sortable: true,
    },

    // { key: "verified", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const jobFilter = ref(null);
  const statusFilter = ref(null);
  const roundFilter = ref(null);
  const recruiterCodeFire = ref('');
  const rawList = ref([]);
  const filteredList = ref([]);
  const isValueUpdated = ref(false);
  const jobOptionsList = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = (val) => {
    console.log("refetching....");
    refUserListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      jobFilter,
      statusFilter,
      roundFilter,
      isValueUpdated,
    ],
    () => {
      refetchData();
    }
  );

  const fetchUsers = (ctx, callback) => {
   getDataandSort({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      isValueUpdated: isValueUpdated.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      round: roundFilter.value ?? [],
      status: statusFilter.value ?? [],
      jobId: jobFilter.value ?? [],
    })
      .then((response) => {
        // console.log(response);
        const { users, total, rawUsers, jobOptions, recruiterCode } = response[1];
        // console.log(rawUsers);
        // console.log('here we have raw users');
        // console.log(users)
        jobOptionsList.value = [{label:"All",value:'all'},...jobOptions]
        recruiterCodeFire.value = recruiterCode
        rawList.value = rawUsers;
        filteredList.value = filteredData;
        callback(users);
        totalUsers.value = total;
        isValueUpdated.value = false;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  

  const resolveUserStatusVariant = (status) => {
    if (status == "Hired" || status == "Joined")
    return "success";
  else if (status == "Rejected" || status == "Resigned")
    return "danger";
  else if (status == "Reschedule" || status == "Working")
    return "warning";
  else if (status == "Did not Pick" || status == "OLSent")
    return "dark";
    else if (status == "--")
    return "secondary";
  else return "primary";
    // if (status === "pending") return "warning";
    // if (status === true) return "success";
    // if (status === false) return "secondary";
    // return "primary";
  };

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    isValueUpdated,
    jobOptionsList,
    recruiterCodeFire,

    resolveUserStatusVariant,
    refetchData,
    rawList,
    filteredList,

    // Extra Filters
    roundFilter,
    statusFilter,
    jobFilter
  };
}