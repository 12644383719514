<template>
  <b-card no-body>

    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Job</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="jobFilter"
            :options="jobOptions"
            multiple
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:jobFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Round</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="roundFilter"
            :options="roundOptions"
            multiple
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:roundFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions" 
            multiple
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { watch } from "@vue/composition-api";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  
  // watch : {
  //   jobOptions : function () {
  //     console.log('jobOptions changed');
  //   }
  // },
  props: {
    jobFilter: {
      type: [Array, null],
      default: null,
    },
    roundFilter: {
      type: [Array, null],
      default: null,
    },
    statusFilter: {
      type: [Array, null],
      default: null,
    },
    jobOptions: {
      type: Array,
      required: true,
    },
    roundOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },

  watch: {
    jobFilter (val){
      // console.log(val)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
