<template>
  <div>

    <!-- Filters -->
    <users-list-filters :round-filter.sync="roundFilter" :status-filter.sync="statusFilter" :job-filter.sync="jobFilter"
      :round-options="roundOptions" :status-options="statusOptions" :job-options="jobOptionsList" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2 student-header-box">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center align-middle justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <!-- <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              > -->
              <b-button variant="primary" @click="DownloadCSV">
                <span class="text-nowrap">Download</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refUserListTable" class="position-relative students-list-table" :items="fetchUsers" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc">
        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="48" square variant="transparent" class="text-secondary" :src="data.item.profile"
                :text="avatarText(data.item.name)" :to="{
                  name: 'candidate-profile',
                  params: { studentId: data.item.id , jobId : data.item.jobId },
                }" />
            </template>
            <b-link :to="{
              name: 'candidate-profile',
              params: { studentId: data.item.id, jobId : data.item.jobId  },
            }" class=" d-block text-nowrap text-capitalize candidate-name" style="font-weight:600;color:#043c57">
              {{ data.item.name }}
            </b-link>
            <small>{{ data.item.email }}</small>
          </b-media>
        </template>



        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(currentRound)="data">
          <span class="text-nowrap text-center" v-if="data.item.currentRound != 0">Round {{ data.item.currentRound
          }}</span>
          <span class="text-nowrap text-center" v-else>--</span>

        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button v-b-tooltip.hover title="Start Chat" v-ripple.400="'rgba(30, 30, 30, 0.15)'" variant="flat-dark" :to="{name:'apps-chat-recruiter',params:{studentId: data.item.id}}"
              class="btn-icon">
              <u-icon icon="fi-rr-comment-alt" size="16" />
            </b-button>
            <b-button v-b-tooltip.hover title="Schedule Interview" v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              :id="`button-interview-${data.item.id}`" variant="flat-dark" class="btn-icon"
              :disabled="!data.item.schedulebutton || data.item.isHired" @click="selectedModal(data.item)">
              <u-icon icon="fi-rr-calendar-clock" size="16" />
            </b-button>
            <!-- v-b-modal="`modal-interview-${data.item.id}`" -->
            <b-modal :id="`modal-interview-${data.item.id}`" ref="my-modal" centered :no-close-on-esc="true" size="sm"
              :no-close-on-backdrop="true" title="Schedule Interview" ok-title="Submit" class="interview-modal"
              cancel-variant="outline-secondary" :hide-header-close="true" @show="resetModal" @hidden="resetModal"
              @ok="(event) => handleOk(event, data.item)">
              <validation-observer :ref="`scheduleInterviewForm-${data.item.id}`" #default="{ invalid }">

                <form @submit.stop.prevent="handleSubmit(data.item)">
                  <b-form-group>
                    <validation-provider #default="{ errors }" name="interview date & time" rules="required">

                      <label> Select Date & Time<label class="text-danger">*</label></label>
                      <flat-pickr v-model="selectedDate" class="form-control"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', altInput: true,altFormat: 'F j, Y H:i', inline: true }"
                        @on-change="listenToOnChangeEvent" />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>
                </form>
              </validation-observer>
            </b-modal>

          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip, VBTooltip, BModal, BFormGroup, VBModal
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, watch } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "./UsersListFilters.vue";
import useUsersList from "./useUsersList";
import rawUserList from "./useUsersList";
import userStoreModule from "./userStoreModule";
import firebase from "firebase/app";
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    UsersListFilters,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,

    vSelect,
    flatPickr,
    ToastificationContent
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    'b-modal': VBModal,

  },
  data() {
    return {
      interviewModalActive: false,
      selectedDate: null,
      required

    }
  },

  created() {
    //changing when store value changed
    this.$store.watch(
      (state) => {
        return this.$store.state.mainUserCodes;
      },
      (newVal, oldVal) => {
        this.fetchUsers;
      },
      { deep: true }
    );
  },
  setup() {
    const isAddNewUserSidebarActive = ref(false);



    const statusOptions = [
      { label: "Did not Pick", value: "Did not Pick" },
      { label: "Reschedule", value: "Reschedule" },
      { label: "Rejected", value: "Rejected" },
      { label: "Shortlisted", value: "Shortlisted" },
      { label: "Hired", value: "Hired" },
      { label: "Joined", value: "Joined" },
      { label: "Offered", value: "OLSent" },
      { label: "Resigned", value: "Resigned" },
      { label: "Working", value: "Working" },

    ];
    const roundOptions = [
      { label: "Round 1", value: 1 },
      { label: "Round 2", value: 2 },
      { label: "Round 3", value: 3 },
      { label: "Round 4", value: 4 },
      { label: "Round 5", value: 5 },
    ];

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      isValueUpdated,
      refUserListTable,
      refetchData,
      recruiterCodeFire,
      jobOptionsList,

      // UI

      resolveUserStatusVariant,
      rawList,
      filteredList,

      // Extra Filters
      roundFilter,
      jobFilter,
      statusFilter
    } = useUsersList();


    function arraytocsv(data) {
      const csvString = [
        [
          "Student Name",
          "Email",
          "Course",
          "Current Round",
          "Status",
        ],
        ...data.map((item) => [
          `"${item.name}"`,
          `"${item.email}"`,
          `"${item.courseName}"`,
          `"Round ${item.currentRound}"`,
          `"${item.status}"`,
        ]),
      ]
        .map((e) => e.join(","))
        .join("\n");

      console.log(csvString);
      return csvString;
      //   return data.map(row =>{

      //     console.log(row)
      //     row
      //     // .map(String)  // convert every value to String
      //     .map(v => v.replaceAll('"', '""'))  // escape double colons
      //     .map(v => `"${v}"`)  // quote it
      //     .join(',')  // comma-separated
      //  } ).join('\r\n')
    }

    function downloadBlob(content, filename, contentType) {
      // Create a blob
      var blob = new Blob([content], { type: contentType });
      var url = URL.createObjectURL(blob);

      // Create a link to download it
      var pom = document.createElement("a");
      pom.href = url;
      pom.setAttribute("download", filename);
      pom.click();
    }
    function DownloadCSV() {
      let data = arraytocsv(filteredList.value);

      downloadBlob(data, "AllCandidates.csv", "text/csv;charset=utf-8;");
    }


    //  watch(roleFilter, (currentValue, oldValue) => {
    //   console.log(currentValue);

    //   console.log()
    //   console.log(oldValue);
    // });

    // const { rawList } = rawUserList();

    // fetchUsers(val, userVal);
    // fetchUsers.forEach((item) => {
    //   if(!rawbatch.includes(item.batch)){
    //     rawbatch.push(item.batch)
    //     batchOptions.push({label : item.batch, value: item.batch})
    //   }
    // })
    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      isValueUpdated,
      // Filter
      avatarText,
      // UI
      resolveUserStatusVariant,
      statusOptions,
      roundOptions,
      jobOptionsList,
      recruiterCodeFire,

      // Extra Filters
      roundFilter,
      jobFilter,
      statusFilter,
      DownloadCSV,
    };
  },

  methods: {
    selectedModal(data) {

      if (data.schedulebutton || data.isHired) {

        this.$bvModal.show(`modal-interview-${data.id}`)
      } else {
        console.log('dont trigger')
        // console.log(this.$bvModal)
        // this.$bvModal[]
        // this.$root.$emit('bv::toggle::modal', `modal-interview-${data.id}`, `button-interview-${data.id}`)

      }
    },

    resetModal() {
      this.selectedDate = null
    },
    listenToOnChangeEvent(selectedDates, dateStr, instance) {
      // this.selectedDate = selectedDates;
      console.log(selectedDates)
    },
    getDifferentDates() {
      let startdate = new Date(this.selectedDate);
      let fulldate = new Date(this.selectedDate).toLocaleDateString("en-GB", {
        // you can use undefined as first argument
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      let stime = startdate.getTime();
      let st = new Date(stime).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      var obj = {
        fulldate: fulldate,
        startTimestamp: startdate,
        starttime: st,
      };
      return obj;
    },
    handleOk(bvModalEvt, data) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit(data)
    },
    async scheduleInterview(obj) {
      console.log(obj.name);
      var timeObj = this.getDifferentDates();
      var u = firebase.auth().currentUser;
      var RC = obj.RecruiterData;
        var studentCode = store.state.mainUserCodes.StudentCode;
      let Ref = firebase.firestore().collection("InterviewSchedule");
      if (typeof obj.profile === "undefined") obj.profile = "";
      if (typeof obj.contact_no === "undefined") obj.contact_no = "";
      if (typeof RC.logo === "undefined") RC.logo = "";
      await Ref.where("jobid", "==", obj.jobId)
        .where("StudentId", "==", obj.id)
        .get()
        .then((snapshot) => {
          if (snapshot.size > 0) {
            console.log("again");
            snapshot.forEach(async (docs) => {
              await docs.ref
                .set(
                  {
                    classes: "event-success",
                    interviewFor: u.uid,
                    //  hrid:this.hr.value,
                    startTime: timeObj.starttime,
                    // currentRound : obj.currentRound + 1,
                    // whichRound:2,
                    // endTime:obj.endtime,
                    whichRound: obj.currentRound + 1,
                    isInterviewDone: false,
                    fulldate: timeObj.fulldate,
                    label: "interviews",
                    // ContactNo: obj.contact_no,
                    StudentId: obj.id,
                    RecruiterId: u.uid,
                    jobid: obj.jobId,
                    RecruiterCode: RC.RecruiterCode,
                    StudentCode:studentCode,
                    FullName: obj.name,
                    profilephoto: obj.profile,
                    JobTitle: RC.JobTitle,
                    CompanyName: RC.CompanyName,
                    logo: RC.logo,
                    startTimestamp: timeObj.startTimestamp,
                    // endTimestamp:obj.endTimestamp,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                  },
                  { merge: true }
                )
                .then(() => {
                  firebase
                    .firestore()
                    .collection("RecruiterJobs")
                    .doc(obj.jobId)
                    .collection("AppliedCandidates")
                    .doc(obj.id)
                    .update({
                      // currentRound : obj.currentRound + 1,
                      isInterviewDone: false,
                    });
                });
            });
          } else {
            //console.log(obj.uid)
            Ref.doc()
              .set({
                classes: "event-success",
                //  hrid:this.hr.value,
                startTime: timeObj.starttime,
                interviewFor: u.uid,
                currentRound: obj.currentRound + 1,
                // endTime:obj.endtime,
                fulldate: timeObj.fulldate,
                label: "interviews",
                // ContactNo: obj.contact_no,
                whichRound: obj.currentRound + 1,
                isInterviewDone: false,
                StudentId: obj.id,
                RecruiterId: u.uid,
                jobid: obj.jobId,
                FullName: obj.name,
                profilephoto: obj.profile,
                RecruiterCode: RC.RecruiterCode,
                StudentCode:studentCode,
                JobTitle: RC.JobTitle,
                CompanyName: RC.CompanyName,
                logo: RC.logo,
                startTimestamp: timeObj.startTimestamp,
              })
              .then(() => {
                firebase
                  .firestore()
                  .collection("RecruiterJobs")
                  .doc(obj.jobid)
                  .collection("AppliedCandidates")
                  .doc(obj.id)
                  .update({
                    // currentRound : obj.currentRound + 1,
                    isInterviewDone: false,
                  });
              });
          }
        })
        .then(() => {
          // this.Interviewactive = false;
                        this.isValueUpdated = true;
          this.$bvModal.hide(`modal-interview-${obj.id}`)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Interview Scheduled",
              icon: "AlertTriangleIcon",
              variant: "success",
              text: `Interview  scheduled successfully for ${timeObj.fulldate} at ${timeObj.starttime}!`,

            },
          });

        });
    },
    handleSubmit(data) {
      // Exit when the form isn't valid

      this.$refs[`scheduleInterviewForm-${data.id}`].validate().then(async success => {
        if (success) {
          this.scheduleInterview(data)
          // Hide the modal manually
          // this.$nextTick(() => {
          //   this.$refs['my-modal'].toggle('#toggle-btn')
          // })
        } else {
          return
        }
      })

    },
  },
};
</script>

<style lang="scss" scoped>
.interview-modal {
  .modal-backdrop {
    background-color: rgba(4, 60, 87, 0.2)
  }
}
.candidate-name{
  &:hover {
    color: #039be5 !important;
          transition: all 0.25s ease;

    }
}
.per-page-selector {
  width: 90px;
  //height:28px;
  font-size: 14px;
  border-radius: 8px;
}

.vs__dropdown-toggle {
  border-radius: 8px;

}

.student-header-box {
  .form-control {
    height: 38px;
    border-radius: 12px;
  }

}

.students-list-table ::v-deep td:nth-child(4) {
  text-align: center;
}

.students-list-table ::v-deep td:nth-child(5) {
  text-align: center;
}

.students-list-table ::v-deep td:nth-child(6) {
  text-align: center;
}

.students-list-table ::v-deep th:nth-child(4) {
  text-align: center;
}

.students-list-table ::v-deep th:nth-child(5) {
  text-align: center;
}

.students-list-table ::v-deep th:nth-child(6) {
  text-align: center;
}

.students-list-table ::v-deep thead {
  tr:first-child {
    th {
      border-top: 1px solid #efefef;
    }
  }

  tr:first-child {
    th:first-child {
      border-top-left-radius: 0px;
    }

    th:last-child {
      border-top-right-radius: 0px;
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
